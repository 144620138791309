.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.splash_image {
  height: 700px; 
  width: 100%;
  position: relative;
  overflow: hidden;
}

.home_image {
  filter: blur(0px);
  height: auto;
  width: 100%;
}

.top_image_container {
    position: absolute; 
    top: 140px;
    right: 40px;
    padding-left: 40px;
    margin-top: 30px; 
    font-weight: 300;
    z-index: 30; 
    width: 10%; 
    height: 200px;
}

.date_text {
  color: white;
  font-size: 80em;
  z-index: 30; 
}

.Main-Container {
  background-color: white;
  /* min-height: 100vh; */
  /* height: 100vh; */
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: -40px;
}

.center_absolute {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}




.bottom_clip {

  clip-path: polygon(100% 90%, 100% 0, 0 0, 0 90%, 50% 100%);
}

.top_bottom_clip {
  clip-path: polygon(100% 90%, 100% 0, 50% 10%, 0 0, 0 90%, 50% 100%);
}

.opening_container {
  text-align: center;
  width: 30%;
}

.red_circle {
  border-radius: 50px;
  background-color: #E46867;
  height: 40px;
  width: 40px;
  outline: none;
  border-width: 0px;
  cursor: pointer;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px
}
.gg-chevron-down::after {
  content: "";
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
}

.mailing_image {
  height: auto;
  width: 100%;
}

.closing_prayer_container {
    width: 100%;
    height: auto;
    /* padding: 0 20px 0 20px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: black;
}

.bottom_container {
  margin-top: 30px;
  height: 700px;
  width: 100%;
  position: relative; 
  overflow: hidden;
}

.yellow_circle {
  border-radius: 50px;
  background-color: #E9AA6A;
  height: 40px;
  width: 40px;
  outline: none;
  border-width: 0px;
  cursor: pointer;
}

.blue_circle {
  border-radius: 50px;
  background-color: #554DC6;
  height: 40px;
  width: 40px;
  outline: none;
  border-width: 0px;
  cursor: pointer;
}

.green_circle {
  border-radius: 50px;
  background-color: #8FD3B1;
  height: 40px;
  width: 40px;
  outline: none;
  border-width: 0px;
  cursor: pointer;
}

.center_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hidden {
  display: none;
}

p {
  line-height: 2.3em;
}

.oms_plug {
  width: 40%;
  height: 100%;
}

.oms_image_container {
  width: 40%;
  height: 100%;
}

.center_flex_row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.gray_div {
  /* background-color: #414141; */
  width: 100%;
  height: 100px;
  border-radius: 30px;
}

#oms {
  height: 700px;
  width: 100%;
}

.blue_button {
  margin-top: 50px;
  border-radius: 20px;
  background-color: #007FFF;
  height: auto;
  padding: 10px;
  width: 200px;
  color: white;
  font-size: 20px;
  outline: none;
  border-width: 0px;
  cursor: pointer;
}

#input_container {
  position: relative;
  width: 100%;
}

#input_container > img {
  position: absolute;
  margin: auto 0;
  /* top: 50%; */
  left: 180px;
}

#input_container > input {
  padding-left: 60px;
  font-size: 20px;
  border-width: 0px;
}

textarea:focus, input:focus{
  outline: none;
}

#closing_prayer {
  width: 100%;
}

.abcf {
  height: 700px;
  width: 100%;
  position: relative; 
  overflow: hidden;

}


@media only screen and (max-width: 600px) {
  .opening_container {
    width: 90%;
  }
  .bottom_container {
    flex-direction: column;
    height: auto !important;
  }

  .image_email_container {
    top: 0px !important;
    height: 0px !important;
  }


  .home_image {
    height: 100%;
  }

  .splash_image {
    height: 400px;
  }

  .dateText {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 50px;
  }

  .mailing_image {
    height: auto;
    width: 150%;
  }

  .date_container {
    margin-top: 0px !important;
    top: 0px !important;
  }

  .top_image_container {
    margin-top: 0px !important;
    top: 0px !important;
  }

  .top_bottom_clip {
    height: auto !important;
  }

  #scroll_indicator {
    display: none;
  }

  .abcdefg {
    width: 80% !important;
  }

  .scripture_image {
    height: auto;
    width: 100%;
  }

  .bottom_iphone_image {
    width: 100% !important;
  }

  .oms_image_container {
    width: 90% !important;
  }

  .apple_badge {
    display: none !important;
  }

  .apple_badge_mobile {
    display: flex !important;
    width: 70% !important;
  }

  .oms_image_logo {
    width: 40% !important;
  }

  .lords_image {

  }

  .scripture_text_container {
    margin-top: 0px !important;
    width: 100% !important;
    padding-top: 20px !important;
  }

  .lords_text {
    width: 90% !important;
    margin-top: 0px !important;
  }

  .oms_plug {
    width: 90%;
  }

}