body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.border {
  /* border: 1px solid red; */
}

.image-back {
  overflow: hidden;
  clip-path: inset(0px 0px 30% 0px);
}

